<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $t("ogza.staffList.title")
            }}</span>
            <span class="text-muted fs-7">{{ $t("ogza.staffList.desc") }}</span>
          </h3>

          <!--begin::Actions-->
          <div class="justify-content-end">
            <router-link to="/invite-staff">
              <button type="submit" class="btn btn-sm btn-primary">
                {{ $t("ogza.staffList.inviteStaff.title") }}
              </button>
            </router-link>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-5">
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div
              v-if="isLoading"
              style="
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="text-center px-5 pb-7">
                <img
                  alt="Logo"
                  class="max-h-25px"
                  src="/media/loading_icon.gif"
                  style="width: 35px"
                />
              </div>
            </div>
            <div class="table-responsive" v-else-if="staffList.length > 0">
              <table
                class="table align-middle table-row-dashed fs-6 gy-5 no-footer"
                id="kt_customers_table"
                role="grid"
              >
                <!--begin::Table-->
                <thead>
                  <tr
                    class="
                      text-start text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                    role="row"
                  >
                    <td width="15%">
                      {{ $t("ogza.staffList.name") }}
                      {{ $t("ogza.staffList.surname") }}
                    </td>
                    <td width="15%">{{ $t("ogza.staffList.email") }}</td>
                    <td width="15%">{{ $t("ogza.staffList.department") }}</td>
                    <td width="15%">{{ $t("ogza.staffList.mission") }}</td>
                    <td width="15%">{{ $t("ogza.staffList.phone") }}</td>
                    <td width="15%">{{ $t("ogza.staffList.status") }}</td>
                    <td width="10%">{{ $t("common.process") }}</td>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600 text-center">
                  <tr
                    class="odd"
                    v-if="staffList.length > 0"
                    v-for="(staff, index) in staffList"
                    :key="index"
                  >
                    <td v-if="staff.staff.name || staff.staff.surname">{{ staff.staff.name }} {{ staff.staff.surname }}</td>
                    <td v-else> 
                      <span class="badge badge-secondary">
                          {{
                            $t(
                              "modals.interview.candidate.noPositionSuitability"
                            )
                          }}
                        </span>
                    </td>
                    <td>{{ staff.staff.email }}</td>
                    <td v-if="staff.staff.department">{{ staff.staff.department }}</td>
                    <td v-else> 
                      <span class="badge badge-secondary">
                          {{
                            $t(
                              "modals.interview.candidate.noPositionSuitability"
                            )
                          }}
                        </span>
                    </td>
                    <td v-if="staff.staff.position">{{ staff.staff.position }}</td>
                    <td v-else> 
                      <span class="badge badge-secondary">
                          {{
                            $t(
                              "modals.interview.candidate.noPositionSuitability"
                            )
                          }}
                        </span>
                    </td>
                    <td>
                      +{{ staff.staff.countryCodeNumber }}
                      {{ staff.staff.phoneNumber }}
                    </td>
                    <td>
                      <span
                        v-if="staff.staff.isActive == false"
                        class="badge fs-8 fw-bolder badge-light-warning"
                      >
                        Onay Bekliyor
                      </span>
                      <span
                        v-else
                        class="badge fs-8 fw-bolder badge-light-success"
                      >
                        Aktif
                      </span>
                    </td>
                    <td>
                      <!--
                      <a
                        href="#"
                        class="
                          btn
                          btn-icon
                          btn-bg-light
                          btn-active-color-primary
                          btn-sm
                          me-1
                        "
                      >
                        <span class="svg-icon svg-icon-3"
                          ><svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.3"
                              d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                              fill="black"
                            ></path></svg></span></a
                      >
                      --><a
                        href="#"
                        class="btn btn-icon btn-danger btn-sm"
                        @click="deleteStaff(staff.staff.id)"
                                            v-show="staff.staff.role != 'TENANT_ADMIN'"
                        ><span class="svg-icon svg-icon-3"
                          ><svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                              fill="black"
                            ></path>
                            <path
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                              fill="black"
                            ></path></svg></span
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table-->
              </table>
            </div>
            <div v-else class="card-px text-center">
              <div class="text-center px-5 pb-7"></div>
              <!--begin::Description-->
              <p class="text-gray-700 fs-6 fw-bold">
                <span>{{ $t("ogza.staffList.zeroCount") }}</span>
              </p>
              <!--end::Description-->
            </div>
          </div>

          <!--begin::Pagination-->
          <div
            class="d-flex flex-stack flex-wrap pt-10"
            v-if="staffList.length != 0"
          >
            <!--begin::Controls-->
            <div class="d-flex flex-wrap my-1">
              <!--begin::Select wrapper-->
              <div class="m-0">
                <!--begin::Select-->
                <select
                  name="status"
                  data-control="select2"
                  data-hide-search="true"
                  class="
                    form-select form-select-white form-select-sm
                    fw-bolder
                    w-125px
                  "
                  v-model="pageSize"
                  @change="getStaffList(1)"
                >
                  <option value="5">5</option>
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span>{{ $t("common.table.pagination.desc2") }}</span>
                <!-- Sayfa başı öğe sayısı -->

                <!--end::Select-->
              </div>
              <!--end::Select wrapper-->
            </div>
            <!--end::Controls-->

            <div class="fs-6 fw-bold text-gray-700">
              {{ totalItems }} {{ $t("common.table.pagination.desc") }}
              <!-- sonuç arasından -->
              {{ (currentPage - 1) * pageSize + 1 }} -
              {{ Math.min(currentPage * pageSize, totalItems) }}
              {{ $t("common.table.pagination.desc1") }}
              <!-- sonuç gösteriliyor. -->
            </div>

            <!--begin::Pages-->
            <ul class="pagination">
              <li
                class="page-item previous"
                @click="getStaffList(currentPage - 1)"
                :class="{ disabled: currentPage === 1 }"
              >
                <a href="#" class="page-link"><i class="previous"></i></a>
              </li>

              <li
                v-for="page in pageCount"
                :key="page"
                class="page-item"
                :class="{ active: currentPage === page }"
              >
                <a href="#" class="page-link" @click="getStaffList(page)">{{
                  page
                }}</a>
              </li>

              <li
                class="page-item next"
                @click="getStaffList(currentPage + 1)"
                :class="{ disabled: currentPage === pageCount }"
              >
                <a href="#" class="page-link"><i class="next"></i></a>
              </li>
            </ul>
            <!--end::Pages-->
          </div>
          <!--end::Pagination-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<style>
body.swal2-height-auto {
  height: 100% !important
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { StaffListModel } from "@/domain/staff/staff-list/model/StaffListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { DeleteStaffModel } from "@/domain/staff/delete-staff/model/DeleteStaffModel";

export default defineComponent({
  name: "staff-list",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const loading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const staffController = store.state.ControllersModule.staffController;
    const staffList = ref<StaffListModel[]>([]);
    const originalStaffList = ref<StaffListModel[]>([]);

    const isLoading = ref<boolean>(false);
    const isDeletedStaff = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const getStaffList = async (page) => {
      isDeletedStaff.value = false;
      staffList.value = [];
      originalStaffList.value = [];

      isLoading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const staffListModel: StaffListModel = {
        staff: {},
        page: page,
        pageSize: pageSize.value,
      };

      staffController
        .staffList(staffListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((staff) => {
              totalItems.value = staff.pagination.total;
              currentPage.value = staff.pagination.page;
              pageCount.value = staff.pagination.pageCount;
              staffList.value.push(staff);
              originalStaffList.value.push(staff);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deleteStaff = (staffId) => {
      const staffDeleteModel: DeleteStaffModel = {
        id: staffId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_STAFF),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          staffController
            .deleteStaff(staffDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isDeletedStaff.value = true;
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_STAFF),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    watch(isDeletedStaff, (newValue) => {
      if (newValue) {
        getStaffList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("ogza.staffList.pageTitle"));
      getStaffList(1);
    });

    return {
      loading,
      staffList,
      isLoading,
      deleteStaff,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
      getStaffList,
    };
  },
});
</script>
